import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { stringify } from "qs";
import { useSelector } from "react-redux";
import { Icon } from "main/javascripts/components/icon/Icon";
import { BasicBlock } from "main/javascripts/components/block/BasicBlock";
import {
  accentColor,
  shadeColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import {
  LINE_ENTRY_LIFF_ID,
  LINE_ENTRY_LIFF_URL,
} from "main/javascripts/constants/Constants";
import { getAvailableFacebookMessengerRegisterUrl } from "main/javascripts/selectors/AccountParamsSelector";
import { isMobile } from "main/javascripts/utils/DeviceUtil";
import { useAppSelector } from "main/javascripts/store";
import { isTrackingParameterStoredSelector } from "main/javascripts/slices/paramSlice";
// import { isFetchedAccountParamSelector } from "main/javascripts/slices/accountParamSlice";
import { getIsFetchedAccountParam } from "main/javascripts/selectors/AccountParamsSelector";
import { getLineEntryValues } from "main/javascripts/utils/trackingUtil";

export const ChatEntry = (): React.ReactElement => {
  const isTrackingParameterStored = useAppSelector(
    isTrackingParameterStoredSelector
  );
  // 旧reducerを消したらこちらを使う
  // const isFetchedAccountParam = useAppSelector(
  //   isFetchedAccountParamSelector
  // );
  const isFetchedAccountParam = useAppSelector(getIsFetchedAccountParam);
  // TODO: 旧reducerをそのまま使用
  const facebookMessengerRegisterUrl: any = useSelector<any>((state) =>
    getAvailableFacebookMessengerRegisterUrl(state)
  );

  const [isMounted, setIsMounted] = useState(false);
  const [lineEntryUrl, setLineEntryUrl] = useState(LINE_ENTRY_LIFF_URL);

  useEffect(() => {
    if (isTrackingParameterStored || isFetchedAccountParam) {
      const paramString = stringify(getLineEntryValues());
      setLineEntryUrl(`${LINE_ENTRY_LIFF_URL}?${paramString}`);
    }
  }, [isTrackingParameterStored, isFetchedAccountParam]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const renderLineEntryButton = () => {
    if (!isMounted || !isTrackingParameterStored) {
      return <div css={buttonSpaceStyle} />;
    }

    if (isMobile()) {
      return (
        <a
          css={[buttonStyle, buttonLineStyle]}
          href={lineEntryUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div css={buttonIconStyle}>
            <Icon styleKey="line" />
          </div>
          <span css={buttonTextStyle}>LINEで相談する</span>
        </a>
      );
    } else {
      return (
        <a
          css={[buttonStyle, buttonMailStyle]}
          href={lineEntryUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span css={buttonTextStyle}>メールで相談する</span>
          <span css={buttonArrowMailStyle} />
        </a>
      );
    }
  };

  return (
    <BasicBlock styleKey="blockFullWidth" backgroundStyleKey="secondary">
      <div css={innerBlockStyle}>
        <div css={titleStyle}>
          経験豊富な旅のプロが
          <br />
          チャットであなたの旅行をサポート
        </div>
        <div css={introStyle}>
          たびチャットとは、いつでもどこでも気軽に相談できるチャット版の旅行代理店です。
          <br />
          旅行の予約だけでなく、必要な申請・準備・現地サポートと、旅行終了まで旅のプロがチャットでサポートします。
        </div>
        {facebookMessengerRegisterUrl || LINE_ENTRY_LIFF_ID ? (
          <div css={buttonBlockStyle}>
            {facebookMessengerRegisterUrl && (
              <a
                css={[buttonStyle, buttonFBStyle]}
                href={facebookMessengerRegisterUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div css={buttonIconStyle}>
                  <Icon styleKey="facebook" />
                </div>
                <span css={buttonTextStyle}>Facebookで相談</span>
              </a>
            )}
            {LINE_ENTRY_LIFF_ID && renderLineEntryButton()}
          </div>
        ) : (
          <div css={buttonBlockStyle}>
            <div css={buttonSpaceStyle} />
          </div>
        )}
      </div>
    </BasicBlock>
  );
};

const innerBlockStyle = css`
  max-width: 620px;
  margin: 0 auto;
  padding: 2rem ${space.atom};
  box-sizing: border-box;
  ${moreThanBreakpoint("desktop")} {
    max-width: 580px;
  }
`;
const titleStyle = css`
  font-size: 1.1875rem;
  line-height: ${lineHeight.headline2};
  padding: ${space.atom};
  text-align: center;
  font-weight: ${fontWeight.bold};
  letter-spacing: 0.05em;
  color: ${textColor.primaryDarkColor};
  ${moreThanBreakpoint("tablet")} {
    font-size: ${fontSize.title};
    line-height: ${lineHeight.title};
  }
`;
const introStyle = css`
  font-size: ${fontSize.body};
  line-height: ${lineHeight.body1};
  padding: ${space.atom};
  color: ${textColor.secondaryDarkColor};
`;
const buttonBlockStyle = css`
  text-align: center;
  padding: ${space.atom2x} ${space.atom} ${space.atom};
  margin: 0 auto;
  max-width: 460px;
  ${moreThanBreakpoint("tablet")} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: ${space.atom2x};
    max-width: 100%;
  }
`;
const buttonStyle = css`
  box-sizing: border-box;
  width: 240px;
  height: 50px;
  text-align: center;
  position: relative;
  padding-left: 50px;
  margin-bottom: ${space.atom};
  color: #fff;
  font-size: ${fontSize.mediumHeading};
  letter-spacing: 0.04em;
  transition: background 0.4s ease;
  text-decoration: none;
  display: inline-block;
  border-radius: ${borderRadius.normal};
  &:last-child {
    margin-bottom: 0;
  }
  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 50px;
  }
  ${moreThanBreakpoint("tablet")} {
    margin-bottom: 0;
  }
`;
const buttonLineStyle = css`
  background-color: ${accentColor.lineColor};
  &:hover {
    background-color: ${shadeColor(accentColor.lineColor, -10)};
  }
`;
const buttonFBStyle = css`
  background-color: ${accentColor.fbColor};
  &:hover {
    background-color: ${shadeColor(accentColor.fbColor, -15)};
  }
`;
const buttonMailStyle = css`
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  background-color: ${accentColor.primaryColor};
  margin: 0 auto;
  span {
    &:first-of-type {
      flex: 1;
      padding: 0 ${space.atom};
    }
  }
  &:after {
    content: none;
  }
  &:hover {
    background-color: ${accentColor.primaryDarkColor};
  }
`;
const buttonIconStyle = css`
  font-size: 26px;
  position: absolute;
  left: 13px;
  top: 50%;
  margin-top: -13px;
`;
const buttonTextStyle = css`
  display: inline-block;
  line-height: 50px;
`;
const buttonSpaceStyle = css`
  height: 50px;
`;
const buttonArrowStyle = css`
  position: relative;
  display: block;
  margin: 8px;
  width: 34px;
  height: 34px;
  background-color: #fff;
  border-radius: 2px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    display: block;
    width: 14px;
    height: 2px;
    background-color: ${accentColor.lineColor};
    transform: translateY(-50%);
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    display: block;
    width: 6px;
    height: 6px;
    border: 2px solid ${accentColor.lineColor};
    border-bottom: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg);
  }
`;
const buttonArrowMailStyle = css`
  ${buttonArrowStyle};
  &:before {
    background-color: ${accentColor.primaryColor};
  }
  &:after {
    border-color: ${accentColor.primaryColor};
  }
`;
